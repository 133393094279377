import { graphql, useStaticQuery } from "gatsby";
export default function usePartnersData() {
    const data = useStaticQuery(graphql`
  {
    allNodePartners(
      filter: {field_target_site: {drupal_internal__target_id: {eq: 451}}, langcode: {eq: "en"}}
    ) {
      nodes {
        entityId: drupal_internal__nid
        title
        fieldPartnersAddress: field_partners_address {
          countryCode: country_code
        }
        fieldPartnersImageField: field_partners_image_field {
          alt
        }
        relationships {
          fieldPartnersImageField: field_partners_image_field {
            gatsbyImage(layout: FULL_WIDTH, width: 10, placeholder:BLURRED)
          }
        }
      }
    }
    }`)

    let result = data?.allNodePartners?.nodes//?.filter(node=>((node.path !== '/uk/solutions') || (node.path !== '/solutions')));
    

    return result;
}
