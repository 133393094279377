import React, {useState, useMemo, useEffect } from "react";
import loadable from '@loadable/component'
import useAccruentHomePage from '../hooks/useAccruentHomePage';
import "../scss/search/_searchPage.scss";
import "../scss/_myAccruent.scss";
import getTeaserCarouselDataFromResource from "../components/helpers/getTeaserCarouselDataFromResource"
import { navigate, useLocation } from "@reach/router";
import getPathWithQueryParam from "src/../helpers/getPathWithQueryParam";
import getCookie from 'src/../helpers/getCookieParser';
import CirclUp from "../../static/circle-up-solid.svg";
import bannerLogo from '../images/banner/my-accurent-banner-logo.png';
import useSolutionData from "src/hooks/useSolutionData";
import usePartnersData from "src/hooks/usePartnersData";
import useProductData from "src/hooks/useProductData";
import createRelatedResources from "src/components/helpers/createRelatedResources";
// import Partner from  "src/components/myAccruent/Partner";
import { Partner } from "src/components/pageComponents";
import { LocalizedLink,SEO,SearchField,TeaserCarouselBlock } from "src/components/commonComponents";
import getOGMarkup from "src/components/helpers/getOGMarkup";
const SelectionModal = loadable(() => import("src/components/commonComponents"), {
  resolveComponent: (components) => components.SelectionModal
 });
const AccruentPageSupport = loadable(() => import('src/components/pageComponents'), {
  resolveComponent: (components) => components.AccruentPageSupport
 })
const RecentResources = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.RecentResources
 });
const Resources = loadable(() => import('src/components/solutions/Resources'))
const Products = loadable(() => import("src/components/pageComponents"), {
  resolveComponent: (components) => components.Products
 });
const ComponentHeader = loadable(() => import("src/components/pageComponents"), {
  resolveComponent: (components) => components.ComponentHeader
 });
const Calendly = loadable(() => import("src/components/commonComponents"), {
  resolveComponent: (components) => components.Calendly
 });
const IframeTab = loadable(() => import("src/components/pageComponents"),{
  resolveComponent: (components) => components.IframeTab
});
const getImageObject = require("src/../helpers/getImageObject")


const selectedSolutionKey = "personalizedSelectedSolutions"
const selectedProductKey = "personalizedSelectedProducts"
const customerKey = "isCustomer"
const customerStatusList = [
  { key: 'yes' , label: 'Yes' },
  { key: 'no' , label: 'No' },
]
const solutionList = [
  { key: 'a770735d-c33d-5bdd-a4bb-d5ececd3d95e', label: 'CMMS' },
  { key: 'dad58122-40fb-5175-b9f2-789595f15e12', label: 'Desk and Room Booking' },
  { key: 'a1eec28c-0afb-51a9-aeb0-10209002215d', label: 'Energy Management' },
  { key: 'abb1d496-3caa-54e1-aaae-d2eae560850e', label: 'Engineering Document Management' },
  { key: '63bc6e85-147a-50ec-82a1-cdf6dca2cff9', label: 'Enterprise Asset Management' },
  { key: '5a5d2dd2-06a5-5a7c-8d65-e0f902b82d7e', label: 'Event Management' },
  { key: '1edb0914-2ab8-5f5c-9f9e-0174f0313973', label: 'Facility Asset Management' },
  { key: 'f777933f-f878-5d99-a303-ef189ffaae71', label: 'Healthcare Technology Management' },
  { key: 'e82d197e-0376-5745-b6aa-a1c6e2615d75', label: 'IoT Remote Monitoring' },
  { key: 'efefb9db-12ec-5232-972a-b3ac529ce0f6', label: 'Lease Administration & Accounting' },
]
const productList = [
  {
    key: "4037aec4-37af-4a21-82fe-640fb35a5bed",
    label: 'Maintenance Connection',
  },
  {
    key: "81968dd2-401f-4715-8420-0bcbc3cdb693",
    label: 'EMS',
  },
  {
    key: "05ad9850-b65c-4d2c-a8b2-81fba16040e8",
    label: 'EMS Flex',
  },
  {
    key: "46c2d08c-4df4-4005-8d0b-e27f5e46cae2",
    label: 'Data Insights',
  },
  {
    key: "88577a89-171e-45e9-9ea0-7201c0d9e3a3",
    label: 'FAMIS 360',
  },
  {
    key: "3da8a437-b8c5-4e29-ad81-263b7f879902",
    label: 'TMS',
  },
  {
    key: "a7a2e954-cabb-4f95-a426-4458f2ce7664",
    label: 'Service Channel',
  },
  {
    key: "eae74d32-0a7d-46c0-8ed0-4ee091016ef5",
    label: 'Accruent Observe for Energy Management',
  },
  {
    key: "a72cdc15-e517-4213-9d7d-9a3a5cbdf136",
    label: 'Lucernex',
  },
  {
    key: "e77f4b13-7739-48b3-9358-a9ad9c82a636",
    label: 'Siterra',
  },
  {
    key: "3559251c-10e7-4831-a79e-7bfb9839beff",
    label: 'Meridian',
  },
  {
    key: "8f60ddb7-d31b-41ef-a665-cc53f479ece9",
    label: 'Vx observe',
  },
  {
    key: "930d7e19-fd83-443a-bd0d-a64295847896",
    label: 'Vx maintain',
  },
  {
    key: "12baf741-7b65-430f-963e-d84c06c06482",
    label: 'Vx sustain',
  },
  {
    key: "9193b165-d9bb-445a-9f48-7f3994e8f4f3",
    label: 'Vx field',
  }
]
const relatedProductsMapping = {
  "eae74d32-0a7d-46c0-8ed0-4ee091016ef5": ["8f60ddb7-d31b-41ef-a665-cc53f479ece9", "4037aec4-37af-4a21-82fe-640fb35a5bed", "81968dd2-401f-4715-8420-0bcbc3cdb693",],
  "46c2d08c-4df4-4005-8d0b-e27f5e46cae2": ["3da8a437-b8c5-4e29-ad81-263b7f879902", "eae74d32-0a7d-46c0-8ed0-4ee091016ef5"],
  "81968dd2-401f-4715-8420-0bcbc3cdb693": ["05ad9850-b65c-4d2c-a8b2-81fba16040e8", "eae74d32-0a7d-46c0-8ed0-4ee091016ef5", "a72cdc15-e517-4213-9d7d-9a3a5cbdf136",],
  "05ad9850-b65c-4d2c-a8b2-81fba16040e8": ["81968dd2-401f-4715-8420-0bcbc3cdb693", "eae74d32-0a7d-46c0-8ed0-4ee091016ef5", "a72cdc15-e517-4213-9d7d-9a3a5cbdf136",],
  "88577a89-171e-45e9-9ea0-7201c0d9e3a3": ["81968dd2-401f-4715-8420-0bcbc3cdb693", "eae74d32-0a7d-46c0-8ed0-4ee091016ef5"],
  "a72cdc15-e517-4213-9d7d-9a3a5cbdf136": ["930d7e19-fd83-443a-bd0d-a64295847896", "8f60ddb7-d31b-41ef-a665-cc53f479ece9", "81968dd2-401f-4715-8420-0bcbc3cdb693",],
  "4037aec4-37af-4a21-82fe-640fb35a5bed": ["3559251c-10e7-4831-a79e-7bfb9839beff", "8f60ddb7-d31b-41ef-a665-cc53f479ece9", "eae74d32-0a7d-46c0-8ed0-4ee091016ef5"],
  "e77f4b13-7739-48b3-9358-a9ad9c82a636": ["4037aec4-37af-4a21-82fe-640fb35a5bed", "8f60ddb7-d31b-41ef-a665-cc53f479ece9",],
  "3559251c-10e7-4831-a79e-7bfb9839beff": ["4037aec4-37af-4a21-82fe-640fb35a5bed", "8f60ddb7-d31b-41ef-a665-cc53f479ece9", "eae74d32-0a7d-46c0-8ed0-4ee091016ef5"],
  "a7a2e954-cabb-4f95-a426-4458f2ce7664": ["8f60ddb7-d31b-41ef-a665-cc53f479ece9", "eae74d32-0a7d-46c0-8ed0-4ee091016ef5", "a72cdc15-e517-4213-9d7d-9a3a5cbdf136",],
  "3da8a437-b8c5-4e29-ad81-263b7f879902": ["46c2d08c-4df4-4005-8d0b-e27f5e46cae2", "eae74d32-0a7d-46c0-8ed0-4ee091016ef5"],
  "8f60ddb7-d31b-41ef-a665-cc53f479ece9": ["eae74d32-0a7d-46c0-8ed0-4ee091016ef5", "4037aec4-37af-4a21-82fe-640fb35a5bed", "3559251c-10e7-4831-a79e-7bfb9839beff",],
  "930d7e19-fd83-443a-bd0d-a64295847896": ["a7a2e954-cabb-4f95-a426-4458f2ce7664", "eae74d32-0a7d-46c0-8ed0-4ee091016ef5", "8f60ddb7-d31b-41ef-a665-cc53f479ece9",],
  "12baf741-7b65-430f-963e-d84c06c06482": ["8f60ddb7-d31b-41ef-a665-cc53f479ece9", "eae74d32-0a7d-46c0-8ed0-4ee091016ef5", "a7a2e954-cabb-4f95-a426-4458f2ce7664",],
  "9193b165-d9bb-445a-9f48-7f3994e8f4f3": ["e77f4b13-7739-48b3-9358-a9ad9c82a636", "4037aec4-37af-4a21-82fe-640fb35a5bed", "8f60ddb7-d31b-41ef-a665-cc53f479ece9",]
}
let support = [
  {
    title: "Contact Us",
    icon: "fa-user-headset",
    desc: "<p>Talk with a support agent</p><p>Americas: +1 877.345.3999</p><p>International: +44 0808.234.3335</p>",
    link: "<a target=_blank href=tel:+1877.345.3999>Call Now ></a>"
  },
  {
    title: "Technical Support",
    icon: "fa-envelope-open-text",
    desc: "<p>Access your Portal Email</p><p><a href=mailto:support@accruent.com}>support@accruent.com</a></p>",
    link: "<a target=_blank href=mailto:support@accruent.com>Email Now ></a>"
  }
]

let NoCustomersupport = [
  {
    title: "Contact Us",
    icon: "fa-user-headset",
    desc: "<p>Talk with a support agent</p><p>Americas: +1 877.345.3999</p><p>International: +44 0808.234.3335</p>",
    link: "<a target=_blank href=tel:+1877.345.3999>Call Now ></a>"
  }
]

let customerSupport = [
  {
    title: "Contact Us",
    icon: "fa-user-headset",
    desc: "<p>Talk with a support agent</p><p>Americas: +1 877.345.3999</p><p>International: +44 0808.234.3335</p>",
    link: "<a target=_blank href=tel:+1877.345.3999>Call Now ></a>"
  },
  {
    title: "Technical Support",
    icon: "fa-envelope-open-text",
    desc: "<p>Access your Portal Email</p><p><a href=mailto:support@accruent.com}>support@accruent.com</a></p>",
    link: "<a target=_blank href=mailto:support@accruent.com>Email Now ></a>"
  },
  {
    title: "Support Policy",
    icon: "fa-list-check",
    desc: "<p>See the Accruent Support Policy</p>",
    link: "<a target=_blank href=https://info.accruent.com/rs/167-BOY-362/images/Accruent%20Support%20Policy.pdf>View Policy ></a>"

  },
  {
    title: "Payment and Billing",
    icon: "fa-user-group",
    desc: "<p>Make a Payment: <a href=mailto:payments@accruent.com> payments@accruent.com </a></p><p>Billing: <a href=mailto:arinvoices@accruent.com> payments@accruent.com </a></p>",
    link: "<a target=_blank href=mailto:payments@accruent.com>Email Policy ></a>"
  },
]

let demos = [
  { key: "8f60ddb7-d31b-41ef-a665-cc53f479ece9", label: "Vx Observe", value: "https://app.storylane.io/demo/za9hwqg1spci" },
  { key: "eae74d32-0a7d-46c0-8ed0-4ee091016ef5", label: "AOEM", value: "https://app.storylane.io/demo/7t4hbz2q3bbi" },
  { key: "05ad9850-b65c-4d2c-a8b2-81fba16040e8", label: "EMS Flex", value: "https://app.storylane.io/demo/zmz1mp1a8fmz" },
  { key: "e77f4b13-7739-48b3-9358-a9ad9c82a636", label: "Siterra", value: "https://app.storylane.io/demo/fwukgdyu5cjl" }
]

let calculators = [
  { key: "4037aec4-37af-4a21-82fe-640fb35a5bed", label: "Maintenance Connection", value: "https://app.calconic.com/api/embed/calculator/6488cb0028c7470029d3e5b5" },
  { key: "3559251c-10e7-4831-a79e-7bfb9839beff", label: "Meridian", value: "https://app.calconic.com/api/embed/calculator/64892da028c7470029d3e74e" },
  { key: "3da8a437-b8c5-4e29-ad81-263b7f879902", label: "TMS", value: "https://app.calconic.com/api/embed/calculator/64890c4c28c7470029d3e6f2" },
  { key: "05ad9850-b65c-4d2c-a8b2-81fba16040e8", label: "EMS Flex", value: "https://app.calconic.com/api/embed/calculator/6488db1328c7470029d3e614" },
  { key: "46c2d08c-4df4-4005-8d0b-e27f5e46cae2", label: "Data Insights", value: "https://app.calconic.com/api/embed/calculator/6488d26c28c7470029d3e5dd" },
  { key: "eae74d32-0a7d-46c0-8ed0-4ee091016ef5", label: "AOEM", value: "https://app.calconic.com/api/embed/calculator/648a30de28c7470029d3eb5e" }
]


const myAccruent = () => {
  const [searchInput, setSearchInput] = useState(undefined);
  const [openModal, setOpenModal] = useState(false);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [openSolutionModal, setOpenSolutionModal] = useState(false);
  const [selectedSolutionId, setSelectedSolutionId] = useState([]);
  const [selectedSolution, setSelectedSolution] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [customerStatus, setCustomerStatus] = useState('');
  const [currentDemos, setCurrentDemos] = useState(demos);
  const [currentCalculators, setCurrentCalculators] = useState(calculators);
  const [userCountry, setUserCountry] = useState('');
  const location = useLocation();
  const solutions = useSolutionData();
  const sitePartners = usePartnersData();
  const siteProducts = useProductData()
  const locale = 'en'
  useEffect(() => {
    let currentSolutions = localStorage.getItem(selectedSolutionKey, null)
    if (currentSolutions) {
      currentSolutions = currentSolutions?.split(",")
      setSelectedSolutionId(currentSolutions)
      if (currentSolutions) {
        setSelectedSolution(getSolutionsByEntityIds(currentSolutions))
      }
    }
    let currentProducts = localStorage.getItem(selectedProductKey, null)
    if (currentProducts) {
      currentProducts = currentProducts?.split(",")
      setSelectedProductId(currentProducts)
      if (currentProducts) {
        setSelectedProducts(getProductsByEntityIds(currentProducts))
      }
    }
    let isCustomer = localStorage.getItem(customerKey, '')
    isCustomer = !isCustomer ? '' : isCustomer
    setCustomerStatus(isCustomer, ()=>{
      if(customerStatus === 'no' && !currentSolutions){
        setCustomerStatus('')
      }
    })

    if (isCustomer === 'no' && !currentSolutions) {
      setOpenSolutionModal(true)
    } else if (isCustomer === 'yes' && !currentProducts) {
      setOpenProductModal(true)
    } else if (isCustomer === '') {
      setOpenModal(true)
    }

  }, [])

  const getCountryFromCoordinates = async (latitude, longitude) => {
    const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      return { countryCode: data.address.country_code, countryName: data.address.country };
    } catch (error) {
      return console.log(error);
    }
  }

  useEffect(() => {
    const successCallback = (position) => {
      const latitude = position.coords.latitude //37.7749;  // Replace with the actual latitude
      const longitude = position.coords.longitude
      getCountryFromCoordinates(latitude, longitude)
        .then(country => {
          setUserCountry(country)
        })
        .catch(error => console.log(error));
    };

    const errorCallback = (error) => {
      console.log(error);
    };

    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);

  }, []);


  const getSolutionsByEntityIds = (entityIds) => {
    let currentSolutions = []
    solutions?.forEach(element => {
      if (entityIds?.includes(element?.context?.key)) {
        currentSolutions.push(element)
      }
    });
    return currentSolutions

  }

  const getProductsByEntityIds = (entityIds) => {
    let productIds = [];
    entityIds?.forEach(id => {
      let tmp = relatedProductsMapping[id]
      if (tmp) {
        productIds = [...productIds, ...tmp]
      }
    })
    productIds = [...new Set(productIds)];

    if (entityIds?.length > 1) {
      productIds = productIds?.filter(p=>!entityIds?.includes(p))
      productIds = productIds
        .map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)?.splice(0, 3)
    }

    let currentProducts = []
    siteProducts?.forEach(element => {
      if (productIds?.includes(element?.key)) {
        currentProducts.push(element)
      }
    });
    let currentDemos = []
    demos?.forEach(element => {
      if (productIds?.includes(element?.key)) {
        currentDemos.push(element)
      }
    });
    setCurrentDemos(currentDemos?.length ? currentDemos : demos)
    let currentCalculators = []
    calculators?.forEach(element => {
      if (productIds?.includes(element?.key)) {
        currentCalculators.push(element)
      }
    });
    setCurrentCalculators(currentCalculators?.length ? currentCalculators : calculators)
    return currentProducts

  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchInput) {
      const cookies = getCookie();
      navigate(getPathWithQueryParam(location, `/search?query=${searchInput}`, cookies));
    }
  };
  const onChange = (e) => {
    setSearchInput(e.target.value);
  };


  const { accruentHomepageData } = useAccruentHomePage();
  const testimonialsRaw = accruentHomepageData?.relationships?.customerTestimonial;

  const testimonialData = useMemo(() => {
    if (!testimonialsRaw) {
      return {};
    }

    const contentCarouselSection = getTeaserCarouselDataFromResource(testimonialsRaw);
    const fieldBrandLogosSection = testimonialsRaw?.relationships?.fieldBrandLogosSection;
    const brandLogoEntity = fieldBrandLogosSection
    const fieldResourceCarouselItem = testimonialsRaw?.relationships?.fieldResourceCarouselItem

    const homepageBackground = (testimonialsRaw.fieldHomepageBackground);

    return {
      contentCarouselSection,
      brandLogoEntity,
      fieldResourceCarouselItem,
      homepageBackground
    };
  }, [testimonialsRaw]);

  const onSolutionModalSubmit = ( solutions ) => {
    localStorage.setItem(selectedSolutionKey, solutions)
    setSelectedSolutionId(solutions)
    setSelectedSolution(getSolutionsByEntityIds(solutions))
    setOpenSolutionModal(false)
  }
  const onCustomerModalSubmit = ( isCustomer ) => {
    localStorage.setItem(customerKey, isCustomer)
    setCustomerStatus(isCustomer)
    setOpenModal(false)
    isCustomer === 'yes' ? setOpenProductModal(true) : setOpenSolutionModal(true);
    
  }

  const onProductModalSubmit = ( products ) => {
    localStorage.setItem(selectedProductKey, products)
    setSelectedProductId(products)
    setSelectedProducts(getProductsByEntityIds(products))
    setOpenProductModal(false)

  }

  const resetPrefrence = () => {
    localStorage.removeItem(selectedSolutionKey)
    localStorage.removeItem(selectedProductKey)
    localStorage.removeItem(customerKey)
    setCustomerStatus('')
    setSelectedSolution(null)
    setSelectedSolutionId([])
    setSelectedProducts(null)
    setSelectedProductId([])
  }

  const closeSolutionModal = (isSkip) => {
    if(isSkip && customerStatus === 'no'){
      setCustomerStatus('')
    }
    setOpenSolutionModal(false);

  }

  const getRelatedResources = () => {
    let currentResources = []
    let uniqueObj = {}
    selectedSolution?.forEach((sol) => {
      const relatedResourcesEntity = sol?.context?.relationships?.fieldRelatedResourceSection
      const newResourceReferenceField = relatedResourcesEntity?.relationships?.fieldResourceReference?.length > 0 ? true : false
      let relatedResources = createRelatedResources(newResourceReferenceField ? relatedResourcesEntity?.relationships?.fieldResourceReference : relatedResourcesEntity?.relationships?.fieldRelatedItem, {
        isTaxonomyPage: true,
        hasDescription: true,
        locale,
        newResourceReferenceField: newResourceReferenceField
      });
      relatedResources?.forEach((resource) => {
        uniqueObj[resource?.entityId] = resource
      })

    })
    currentResources = Object.values(uniqueObj)
    currentResources?.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      let diff = 0
      let firstDate = a?.changed
      let secondDate = b?.changed
      try {
        diff = new Date(secondDate) - new Date(firstDate);
      } catch (error) {

      }
      return diff;
    })
    return currentResources?.splice(0, (customerStatus === 'yes') ? 2 : 1);
  }

  const getRelatedProducts = () => {
    if (customerStatus === 'yes') {
      return selectedProducts;
    }
    let currentProducts = []
    let uniqueObj = {}
    selectedSolution?.forEach((sol) => {
      const relatedProductsEntity = sol?.context?.relationships?.fieldRelatedProducts
      const isProductReference = relatedProductsEntity?.relationships?.fieldProductReference?.length
      const relatedProducts = relatedProductsEntity?.status ? (relatedProductsEntity?.relationships?.fieldProductReference?.length ? relatedProductsEntity?.relationships?.fieldProductReference : relatedProductsEntity?.relationships?.fieldRelatedItems) : []
      relatedProducts?.forEach((product) => {
        if (isProductReference) {
          if (product?.status) {
            product = product?.relationships?.fieldProductReferenceItem
          } else {
            product = undefined
          }
        }
        if (product)
          uniqueObj[product?.entityId] = product
      })
    })
    currentProducts = Object.values(uniqueObj)
    currentProducts?.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      let diff = 0
      let firstDate = a?.changed
      let secondDate = b?.changed
      try {
        diff = new Date(secondDate) - new Date(firstDate);
      } catch (error) {

      }
      return diff;
    })
    const relatedProducts = currentProducts?.splice(0, 3);
    const productIds = relatedProducts?.map(p => p.uuid)
    let currentDemos = []
    demos?.forEach(element => {
      if (productIds?.includes(element?.key)) {
        currentDemos.push(element)
      }
    });
    setCurrentDemos(currentDemos?.length ? currentDemos : demos)
    let currentCalculators = []
    calculators?.forEach(element => {
      if (productIds?.includes(element?.key)) {
        currentCalculators.push(element)
      }
    });
    setCurrentCalculators(currentCalculators?.length ? currentCalculators : calculators)

    return relatedProducts;
  }


  const goToCustomerModal = () => {
    setOpenProductModal(false);
    setOpenSolutionModal(false)
    setOpenModal(true)
 }
 
  const relatedResources = useMemo(() => getRelatedResources(), [selectedSolution, customerStatus])
  const relatedProducts = useMemo(() => getRelatedProducts(), [selectedSolution, customerStatus, selectedProducts])
  const currentPartner = useMemo(() => {
    return sitePartners?.find((partner) => partner?.fieldPartnersAddress?.countryCode?.toUpperCase() === userCountry?.countryCode?.toUpperCase())
  }, [userCountry])

  const OGMarkup = getOGMarkup("My Accruent");

  return (
    <>
      <SEO
        meta={[{ 'name': 'robots', 'content': 'noindex' }]}
        pageUrl="/test-my-accruent"
        OGMarkup={OGMarkup}
      />
      <div className="my-accruent-page tw-mb-[52px] lg:tw-mb-[76px]" id="my-accruent-page">
        {openSolutionModal &&
          <SelectionModal
            title="What Are You Interested In?"
            subTitle="Choose some solutions of interest so we can personalize your experience (up to 3)"
            nextBtnTxt="Finish Personalization"
            maxSelection={3}
            closeModal={closeSolutionModal}
            list={solutionList}
            onBack ={goToCustomerModal}
            onSubmit={onSolutionModalSubmit} />}
          {openProductModal &&
          <SelectionModal
            title="What Accruent software(s) do you have?"
            closeModal={() => { setOpenProductModal(false);}}
            selectedValues={selectedProductId}
            list={productList}
            onBack ={goToCustomerModal}
            onSubmit={onProductModalSubmit} />}
          {openModal &&
            <SelectionModal
              title="Are you currently an Accruent customer?"
              maxSelection={1}
              list={customerStatusList}
              closeModal={() => { setOpenModal(false);}}
              selectedValues={customerStatus}
              onSubmit={onCustomerModalSubmit} />}
        <div className='main-banner'>
          <div className='container !tw-mx-auto !tw-max-w-[1344px] tw-px-[15px]'>
          <div className=' tw-flex-col md:tw-flex-row tw-pt-4 md:tw-pt-[60px] tw-pr-4 md:tw-pr-[60px] tw-pb-4 md:tw-pb-0 tw-pl-4 md:tw-pl-[50px] tw-w-full tw-flex tw-justify-between tw-relative'>
              <div className='tw-flex-col md:tw-flex-row tw-flex'>
                <div className="tw-flex md:tw-flex-none tw-justify-start">
                  <img className='tw-opacity-100 tw-top-[180px] tw-left-[134px] tw-w-[137px] tw-h-36' alt="banner-logo" src={bannerLogo} decoding="async" />
                </div>
                <div className='welcome-text'>
                  <h2 className="tw-text-left tw-text-accruent_sapphire tw-opacity-100 tw-text-mobileXxxlFont md:tw-text-xxxlFont tw-font-Poppins tw-font-bold">Welcome to Your Accruent</h2>
                  <p className="tw-mb-0 tw-text-left tw-font-Roboto tw-text-subtitleLgFont tw-text-accruent_sapphire tw-font-semibold tw-opacity-100">This is your one-stop-shop to find personalized product <br />insights, demos, relevant contact information, and more.</p>
                  {!selectedSolutionId?.length && (customerStatus === '') && <button onClick={() => setOpenModal(!openModal)} className="hover:tw-bg-accruent_sapphire hover:tw-text-accruent_white tw-bg-accruent_white tw-border-2 tw-border-accruent_sapphire tw-rounded-xl tw-py-2.5 tw-px-6 tw-font-Poppins tw-text-accruent_sapphire tw-font-semibold tw-text-base tw-leading-4 tw-tracking-[1.42px] tw-mt-6"> Personalize this page </button>}
                </div>
              </div>
              {currentPartner?.title && <div className="tw-hidden md:tw-block tw-mr-8" >
                <ComponentHeader title="Partners Nearby" icon={"fa-light fa-globe"} path={`/partners/find-a-partner?country=${userCountry?.countryName?.toLowerCase()}`} />
                <Partner title={currentPartner?.title} image={getImageObject(currentPartner?.relationships?.fieldPartnersImageField, currentPartner?.fieldPartnersImageField)} />
              </div>}
            </div>
          </div>
        </div>

        <div className=" tw-bg-accruent_sapphire tw-border tw-border-solid tw-border-accruent_gray1 tw-mt-8">
          <div className="container">
            <div className="tw-px-4 tw-py-8 md:tw-py-8 md:tw-px-[60px]">
              <form role="search" onSubmit={handleSubmit}>
                <SearchField onChange={onChange} searchBarClass={"accurent-search"} searchPadding={"!tw-px-5 md:!tw-px-6"} placeholder="Search on Accruent.com" value={searchInput || ""} />
              </form>
            </div>
          </div>
        </div>
        <div className="tw-relative">
          <div className="container" >
            <div className="md:tw-flex tw-flex-none tw-m-3 md:-tw-m-3">

              <div className="md:tw-flex-none md:tw-w-4/12 tw-block tw-p-3">
              {currentPartner?.title && <div className="tw-block md:tw-mt-8 md:tw-hidden" >
                <ComponentHeader title="Partners Nearby" icon={"fa-light fa-globe"} path={`/partners/find-a-partner?country=${userCountry?.countryName?.toLowerCase()}`} />
                <Partner title={currentPartner?.title} image={getImageObject(currentPartner?.relationships?.fieldPartnersImageField, currentPartner?.fieldPartnersImageField)} />
              </div>}
                {customerStatus === 'yes' && <div className="accruent-support">
                  <ComponentHeader title="Get in Touch" icon={"fa-light fa-messages"} pathTitle="Contact Page >" path="/contact-us" />
                  <AccruentPageSupport isVerticle={true} data={customerSupport} />
                </div>}
                {(!!selectedSolutionId?.length || (customerStatus == 'yes')) && !!relatedProducts?.length && <>
                  <ComponentHeader title="Product Suggestions" icon={"fa-light fa-stars"} path="/products" />
                  <Products data={relatedProducts} />
                </>}
                {customerStatus === 'no' && <div className="accruent-support">
                  <ComponentHeader title="Get in Touch" icon={"fa-light fa-messages"} pathTitle="Contact Page >" path="/contact-us" />
                  <AccruentPageSupport isVerticle={true} data={NoCustomersupport} />
                </div>}

                {(customerStatus === '') && testimonialData?.fieldResourceCarouselItem && (
                  <><ComponentHeader title="Customers Testimonials" icon={"fa-light fa-star"} path="/resources/all-case-studies" />
                    <TeaserCarouselBlock
                      contentCarouselSection={testimonialData?.contentCarouselSection}
                      fieldResourceCarouselItem={testimonialData?.fieldResourceCarouselItem}
                      isHome={true}
                      homepageBackground={testimonialData?.homepageBackground}
                      myAccruentModified={false}
                      marketoDivId='marketo_content_zone'
                    />
                  </>
                      )
                  }
                {customerStatus === '' && <div className="accruent-support">
                  <ComponentHeader title="Get in Touch" icon={"fa-light fa-messages"} pathTitle="Contact Page >" path="/contact-us" />
                  <AccruentPageSupport isVerticle={true} data={support} />
                </div>}
                {(customerStatus === 'no') &&
                  (!!relatedResources?.length &&
                    <>
                      <ComponentHeader title="Resources" icon={"fa-light fa-laptop"} path="/resources" />
                      <Resources individualCardClassName="column is-12-mobile is-12-desktop" title={undefined} isCarousel={false} data={relatedResources} tailwindClassName = "!tw-w-full"  />
                    </>
                  )
                }
                {(customerStatus === '') &&
                 <>
                 <ComponentHeader title="Resources" icon={"fa-light fa-laptop"} path="/resources" />
                 <RecentResources cardCount={1} titleClass={"is-hidden"} heading={""} individualCardClassName="column is-12-mobile is-12-desktop" tailwindClassName = "!tw-w-full" />
               </>
                }

              </div>
              <div class="md:tw-w-4/6 tw-px-2 lg:tw-px-3 tw-mt-3 lg:tw-ml-4">
                {customerStatus === 'yes' && <>
                  <ComponentHeader title="Schedule a Call" icon={"fa-light fa-phone"} />
                  <Calendly calendly={{ calendlyUrl: "https://calendly.com/d/y4x-mm5-ytc/accruent-discovery-call" }} />
                </>}
                <ComponentHeader title="Interactive Demos" icon={"fa-light fa-laptop"} path="/demo" />
                <IframeTab data={currentDemos} />
                <ComponentHeader title="Pricing Calculator" icon={"fa-light fa-calculator"} path="/pricing-calculator" />
                <IframeTab data={currentCalculators} />
                {/* <AccruentPageSupport /> */}
                {customerStatus !== 'yes' && <>
                  <ComponentHeader title="Schedule a Call" icon={"fa-light fa-phone"} />
                  <Calendly calendly={{ calendlyUrl: "https://calendly.com/d/y4x-mm5-ytc/accruent-discovery-call" }} />
                </>}

                {(customerStatus === 'yes') && <>
                  <ComponentHeader title="Resources" icon={"fa-light fa-laptop"} path="/resources" />
                  <RecentResources cardCount={2} titleClass={"is-hidden"} heading={""} individualCardClassName="column is-12-mobile is-6-desktop" tailwindClassName = "md:!tw-w-[48%] !tw-w-full" />
                </>}
              </div>
            </div>
          </div>
          <div className="tw-flex tw-justify-center tw-items-center tw-mt-10 tw-flex-col md:tw-flex-row" >
            {(!!selectedSolutionId?.length || (customerStatus !== '')) && <div>
              <LocalizedLink to={"#my-accruent-page"} className=" hover:tw-bg-[#001c71] hover:!tw-text-white button mr-2 outline-button tw-bg-white !tw-rounded-xl !tw-border-2 !tw-border-[#001c71] !tw-rounded-12 !tw-py-[14px] !tw-px-[24px]" onClick={resetPrefrence}>
                Reset my preferences
              </LocalizedLink>
            </div>}
            <div>
              <LocalizedLink to={"#my-accruent-page"}>
                <img src={CirclUp} alt="up-img" className="tw-mt-4 md:tw-mt-0 tw-mr-0 md:tw-mr-4 tw-w-[40px]" decoding="async" />
              </LocalizedLink>
            </div>
            <div>
              <LocalizedLink to={"#my-accruent-page"} className="link-text">
                Go back up
              </LocalizedLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );

};

export default myAccruent;