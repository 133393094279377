import { graphql, useStaticQuery } from "gatsby";
export default function useProductData() {
  const data = useStaticQuery(graphql`
  {
    allTaxonomyTermAccruentProduct(
      filter: {langcode: {eq: "en"}, status: {eq: true}}
    ) {
      nodes {
        key:  drupal_id
        fieldProductName: field_product_name
        fieldPageTitle: field_page_title
        fieldProductDescription: field_product_description_
        entityUrl: path {
          path: alias
        }
        fieldProductLogo: field_product_logo {
          alt
        }
        relationships {
          fieldProductLogo: field_product_logo {
            gatsbyImage(width: 10, layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
     }`)

  let result = data?.allTaxonomyTermAccruentProduct?.nodes;

  return result;
}
