import { useStaticQuery, graphql } from "gatsby";

// This function returns New Homepage data created on drupal.
export default function useAccruentHomePage(locale = 'en') {
  const data = useStaticQuery(graphql`
{
  allNodeHomepage(
    sort: {fields: created, order: DESC}
    filter: {field_target_site: {drupal_internal__target_id: {eq: 451}}}
    limit: 2
  ) {
    nodes {
      nid: drupal_internal__nid
      created
      fieldTitle: field_title
      body {
			  value
			}
      fieldCtaUrl: field_cta_url {
        title
        url
      }
      langcode
      fieldShowBackgroundPattern: field_show_background_pattern
      fieldSchemaMarkup: field_schema_markup
      metatag {
        attributes {
          content
          href
          name
          rel
        }
        tag
      }
      relationships {
        fieldHeroSection: field_hero_section {
          nid: drupal_internal__nid
          fieldPreHeading: field_pre_heading
          title
          fieldHeroDescription: field_hero_description
          fieldHeroCtaUrl: field_hero_cta_url {
            title
            url
          }
          fieldHeroSecondaryCta: field_hero_secondary_cta {
            title
            url
          }
          showPopup: field_show_cta_button
          entityPublished: status
          fieldImage: field_image {
            alt
          }
          relationships {
            fieldImage: field_image {
              gatsbyImage(fit: COVER, width: 376, layout: CONSTRAINED, placeholder:BLURRED, quality: 10)
            }
            fieldHeroVideo: field_hero_video {
              relationships {
                fieldMediaVideoFile: field_media_video_file {
                  url
                }
              }
              fieldVideoSchemaMarkup: field_video_schema_markup
            }
          }
          fieldVideoSchemaMarkup: field_video_schema_markup
        }
        fieldGenericCta: field_generic_cta {
          status
          nid: drupal_internal__nid
          fieldCtaUrl: field_cta_url {
            title
            url
          }
          fieldGenericCtaBody: field_generic_cta_body {
            value: processed
          }
          fieldHideTitle: field_hide_title
          title
        }
        fieldCardSliderSectionItem: field_card_slider_section_item {
          entityId: id
          nid: drupal_internal__nid
          body {
            value: processed
          }
          fieldTopBorderLineColor: field_top_border_line_color
          title
          entityPublished: status
          relationships {
            fieldCardSliderItem: field_card_slider_item {
              nid: drupal_internal__nid
              entityPublished: status
              fieldCtaUrl: field_cta_url {
                title
                url
              }
              title
              fieldShortDescription: field_short_description
              fieldStatisticsShortDescripti: field_statistics_short_descripti
              fieldSolutionImageLogo: field_solution_image_logo {
                alt
              }
              fieldStatisticsImage: field_statistics_image {
                alt
              }
              relationships {
                fieldSolutionImageLogo: field_solution_image_logo {
                  gatsbyImage(width: 275, placeholder:BLURRED)
                }
                fieldStatisticsImage: field_statistics_image {
                  url
                }
                fieldIndustries: field_industries {
                  tid: drupal_internal__tid
                  entityLabel: name
                  relationships {
                    fieldWhiteIcon: field_white_icon {
                      url
                    }
                    fieldColorIcon: field_color_icon {
                      url
                    }
                  }
                }
              }
            }
          }
        }
        companyNews: field_related_resource_section {
          type: node_type {
            id: drupal_internal__target_id
          }
          title
          entityLabel: title
          fieldHideTitle: field_hide_title
          fieldPreHeading: field_pre_heading
          fieldBody: field_body
          fieldEelatedFormat: field_related_format
          entityPublished: status
          nid: drupal_internal__nid
          uuid: drupal_id
          fieldCtaUrl: field_cta_url {
            url
            title
          }
          relationships {
            fieldRelatedItem: field_related_item {
              ... on node__blog_entry {
                entityId: id
                uuid: drupal_id
                nid: drupal_internal__nid
                type: node_type {
                  id: drupal_internal__target_id
                }
                title
                entityUrl: path {
                  path: alias
                }
                fieldResourceDescription: field_resource_description
                fieldTargetSite: field_target_site {
                  targetId: drupal_internal__target_id
                }
                locale: langcode
                status
                fieldReferenceVisibility: field_reference_visibility
                relationships {
                  fieldMediaImage: field_media_image {
                    fieldImage: field_image {
                      alt
                    }
                    relationships {
                      fieldImage: field_image {
                        gatsbyImage(width: 10, layout: FULL_WIDTH, placeholder:BLURRED)
                      }
                    }
                  }
                }
              }
              ... on node__brochure {
                entityId: id
                uuid: drupal_id
                nid: drupal_internal__nid
                type: node_type {
                  id: drupal_internal__target_id
                }
                title
                entityUrl: path {
                  path: alias
                }
                fieldResourceDescription: field_resource_description
                fieldTargetSite: field_target_site {
                  targetId: drupal_internal__target_id
                }
                locale: langcode
                status
                fieldReferenceVisibility: field_reference_visibility
                relationships {
                  fieldMediaImage: field_media_image {
                    fieldImage: field_image {
                      alt
                    }
                    relationships {
                      fieldImage: field_image {
                        gatsbyImage(width: 10, layout: FULL_WIDTH, placeholder:BLURRED)
                      }
                    }
                  }
                }
              }
              ... on node__case_study {
                entityId: id
                uuid: drupal_id
                nid: drupal_internal__nid
                type: node_type {
                  id: drupal_internal__target_id
                }
                title
                entityUrl: path {
                  path: alias
                }
                fieldResourceDescription: field_resource_description
                fieldTargetSite: field_target_site {
                  targetId: drupal_internal__target_id
                }
                locale: langcode
                status
                fieldReferenceVisibility: field_reference_visibility
                relationships {
                  fieldMediaImage: field_media_image {
                    fieldImage: field_image {
                      alt
                    }
                    relationships {
                      fieldImage: field_image {
                        gatsbyImage(width: 10, layout: FULL_WIDTH, placeholder:BLURRED)
                      }
                    }
                  }
                }
              }
              ... on node__ebook {
                entityId: id
                uuid: drupal_id
                nid: drupal_internal__nid
                type: node_type {
                  id: drupal_internal__target_id
                }
                title
                entityUrl: path {
                  path: alias
                }
                fieldResourceDescription: field_resource_description
                fieldTargetSite: field_target_site {
                  targetId: drupal_internal__target_id
                }
                locale: langcode
                status
                fieldReferenceVisibility: field_reference_visibility
                relationships {
                  fieldMediaImage: field_media_image {
                    fieldImage: field_image {
                      alt
                    }
                    relationships {
                      fieldImage: field_image {
                        gatsbyImage(width: 10, layout: FULL_WIDTH, placeholder:BLURRED)
                      }
                    }
                  }
                }
              }
              ... on node__infographic {
                entityId: id
                uuid: drupal_id
                nid: drupal_internal__nid
                type: node_type {
                  id: drupal_internal__target_id
                }
                title
                entityUrl: path {
                  path: alias
                }
                fieldResourceDescription: field_resource_description
                fieldTargetSite: field_target_site {
                  targetId: drupal_internal__target_id
                }
                locale: langcode
                status
                fieldReferenceVisibility: field_reference_visibility
                relationships {
                  fieldMediaImage: field_media_image {
                    fieldImage: field_image {
                      alt
                    }
                    relationships {
                      fieldImage: field_image {
                        gatsbyImage(width: 720, placeholder:BLURRED)
                      }
                    }
                  }
                }
              }
              ... on node__knowledge_hub_ {
                entityId: id
                uuid: drupal_id
                nid: drupal_internal__nid
                type: node_type {
                  id: drupal_internal__target_id
                }
                title
                entityUrl: path {
                  path: alias
                }
                fieldResourceDescription: field_resource_description
                fieldTargetSite: field_target_site {
                  targetId: drupal_internal__target_id
                }
                locale: langcode
                status
                fieldReferenceVisibility: field_reference_visibility
                relationships {
                  fieldMediaImage: field_media_image {
                    fieldImage: field_image {
                      alt
                    }
                    relationships {
                      fieldImage: field_image {
                        gatsbyImage(width: 720, placeholder:BLURRED)
                      }
                    }
                  }
                }
              }
              ... on node__podcast_episode {
                entityId: id
                uuid: drupal_id
                nid: drupal_internal__nid
                type: node_type {
                  id: drupal_internal__target_id
                }
                title
                entityUrl: path {
                  path: alias
                }
                fieldResourceDescription: field_resource_description
                fieldTargetSite: field_target_site {
                  targetId: drupal_internal__target_id
                }
                locale: langcode
                status
                fieldReferenceVisibility: field_reference_visibility
                relationships {
                  fieldMediaImage: field_media_image {
                    fieldImage: field_image {
                      alt
                    }
                    relationships {
                      fieldImage: field_image {
                        gatsbyImage(width: 720, placeholder:BLURRED)
                      }
                    }
                  }
                }
              }
              ... on node__press_release {
                entityId: id
                uuid: drupal_id
                nid: drupal_internal__nid
                type: node_type {
                  id: drupal_internal__target_id
                }
                title
                entityUrl: path {
                  path: alias
                }
                fieldPublishDate: field_publish_date
                fieldResourceDescription: field_resource_description
                fieldTargetSite: field_target_site {
                  targetId: drupal_internal__target_id
                }
                locale: langcode
                status
                fieldReferenceVisibility: field_reference_visibility
                relationships {
                  fieldMediaImage: field_media_image {
                    fieldImage: field_image {
                      alt
                    }
                    relationships {
                      fieldImage: field_image {
                        gatsbyImage(width: 720, placeholder:BLURRED)
                      }
                    }
                  }
                }
              }
              ... on node__company_news{
								entityId: id
                uuid: drupal_id
                nid: drupal_internal__nid
                type: node_type {
                  id: drupal_internal__target_id
                }
                title
                entityUrl: path {
                  path: alias
                }
                fieldResourceDescription: field_resource_description
                fieldTargetSite: field_target_site {
                  targetId: drupal_internal__target_id
                }
                fieldPublishDate: field_publish_date
                locale: langcode
                status
                fieldReferenceVisibility: field_reference_visibility
                relationships {
                  fieldMediaImage: field_hero_media {
                    fieldImage: field_image {
                      alt
                    }
                    relationships {
                      fieldImage: field_image {
                        gatsbyImage(width: 720 placeholder:BLURRED)
                      }
                    }
                  }
                }
              }
              ... on node__video {
                entityId: id
                uuid: drupal_id
                nid: drupal_internal__nid
                type: node_type {
                  id: drupal_internal__target_id
                }
                title
                entityUrl: path {
                  path: alias
                }
                fieldResourceDescription: field_resource_description
                fieldTargetSite: field_target_site {
                  targetId: drupal_internal__target_id
                }
                locale: langcode
                status
                fieldReferenceVisibility: field_reference_visibility
                relationships {
                  fieldHeroMedia: field_hero_media {
                    fieldImage: field_image {
                      alt
                    }
                    relationships {
                      fieldImage: field_image {
                        gatsbyImage(width: 720, placeholder:BLURRED)
                        url
                      }
                    }
                  }
                  fieldMediaImage: field_media_image {
                    fieldImage: field_image {
                      alt
                    }
                    relationships {
                      fieldImage: field_image {
                        gatsbyImage(width: 720, placeholder:BLURRED)
                      }
                    }
                  }
                }
              }
              ... on node__webinar {
                entityId: id
                uuid: drupal_id
                nid: drupal_internal__nid
                type: node_type {
                  id: drupal_internal__target_id
                }
                title
                entityUrl: path {
                  path: alias
                }
                fieldResourceDescription: field_resource_description
                fieldTargetSite: field_target_site {
                  targetId: drupal_internal__target_id
                }
                locale: langcode
                status
                fieldReferenceVisibility: field_reference_visibility
                relationships {
                  fieldMediaImage: field_media_image {
                    fieldImage: field_image {
                      alt
                    }
                    relationships {
                      fieldImage: field_image {
                        gatsbyImage(width: 720, placeholder:BLURRED)
                      }
                    }
                  }
                }
              }
              ... on node__white_paper {
                entityId: id
                uuid: drupal_id
                nid: drupal_internal__nid
                type: node_type {
                  id: drupal_internal__target_id
                }
                title
                entityUrl: path {
                  path: alias
                }
                fieldResourceDescription: field_resource_description
                fieldTargetSite: field_target_site {
                  targetId: drupal_internal__target_id
                }
                locale: langcode
                status
                fieldReferenceVisibility: field_reference_visibility
                relationships {
                  fieldMediaImage: field_media_image {
                    fieldImage: field_image {
                      alt
                    }
                    relationships {
                      fieldImage: field_image {
                        gatsbyImage(width: 720, placeholder:BLURRED)
                      }
                    }
                  }
                }
              }
            }
          }
        }
        customerTestimonial: field_customer_testimonial_secti {
          type: node_type {
            id: drupal_internal__target_id
          }
          title
          fieldHideTitle: field_hide_title
          body {
            value
          }
          fieldHomepageBackground: field_homepage_background
          relationships {
            fieldBrandLogosSection: field_brand_logos_section {
              nid: drupal_internal__nid
              uuid: drupal_id
              title
              fieldHideTitle: field_hide_title
              fieldShowCtaButton: field_show_cta_button
              fieldCtaUrl: field_cta_url {
                title
                url
              }
              status
              fieldLogoStyle: field_logo_style
              fieldShowBackgroundPattern: field_show_background_pattern
              fieldLogoImages: field_logo_images {
                alt
              }
              field_body
              relationships {
                fieldLogoImages: field_logo_images {
                  gatsbyImage(width: 720, placeholder:BLURRED)
                }
              }
            }
            fieldResourceCarouselItem: field_resource_carousel_item {
              title
              fieldOverrideItem: field_override_item
              fieldCustomerName: field_customer_name
              fieldCustomerFeedback: field_customer_feedback
              fieldCustomerDesignation: field_customer_designation
              fieldTestimonialType: field_testimonial_type
              fieldBrief: field_brief
              fieldCtaUrl: field_cta_url {
                title
                url: url
              }
              fieldTeaserLogo: field_teaser_logo {
                alt
              }
              fieldTeaserImage: field_teaser_image {
                alt
              }
              status
              relationships {
                fieldTeaserImage: field_teaser_image {
                  gatsbyImage(width: 720, placeholder:BLURRED)
                }
                fieldTeaserLogo: field_teaser_logo {
                  gatsbyImage(width: 720, placeholder:BLURRED)
                }
                fieldResourceCarouselItems: field_resource_carousel_items {
                  ... on node__case_study {
                    type: node_type {
                      id: drupal_internal__target_id
                    }
                    title
                    entityUrl: path {
                      path: alias
                    }
                    fieldTargetSite: field_target_site {
                      drupal_internal__target_id
                    }
                    locale: langcode
                    fieldCustomerName: field_customer_name
                    fieldHeroVideoLink: field_hero_video_link
                    fieldCustomerDesignation: field_customer_designation
                    fieldCustomerFeedback: field_customer_feedback
                    fieldReferenceVisibility: field_reference_visibility
                    fieldTestimonialType: field_testimonial_type
                    fieldBrief: field_brief
                    changed
                    status
                    fieldTeaserLogo: field_teaser_logo {
                      alt
                    }
                    fieldTeaserImage: field_teaser_image {
                      alt
                    }
                    relationships {
                      fieldMediaImage: field_media_image {
                        fieldImage: field_image {
                          alt
                        }
                        relationships {
                          fieldImage: field_image {
                            gatsbyImage(width: 720, placeholder:BLURRED)
                          }
                        }
                      }
                      fieldTeaserImage: field_teaser_image {
                        gatsbyImage(width: 720, placeholder:BLURRED)
                      }
                      fieldTeaserLogo: field_teaser_logo {
                        gatsbyImage(width: 1, height: 1, layout: FULL_WIDTH, placeholder:BLURRED)
                      }
                    }
                  }
                  ... on node__press_release {
                    type: node_type {
                      id: drupal_internal__target_id
                    }
                    title
                    entityUrl: path {
                      path: alias
                    }
                    fieldTargetSite: field_target_site {
                      drupal_internal__target_id
                    }
                    locale: langcode
                    fieldCustomerName: field_customer_name
                    fieldCustomerDesignation: field_customer_designation
                    fieldCustomerFeedback: field_customer_feedback
                    fieldReferenceVisibility: field_reference_visibility
                    fieldTestimonialType: field_testimonial_type
                    changed
                    status
                    fieldTeaserLogo: field_teaser_logo {
                      alt
                    }
                    fieldTeaserImage: field_teaser_image {
                      alt
                    }
                    relationships {
                      fieldMediaImage: field_media_image {
                        fieldImage: field_image {
                          alt
                        }
                        relationships {
                          fieldImage: field_image {
                            gatsbyImage(width: 720, placeholder:BLURRED)
                          }
                        }
                      }
                      fieldTeaserImage: field_teaser_image {
                        gatsbyImage(width: 720, placeholder:BLURRED)
                      }
                      fieldTeaserLogo: field_teaser_logo {
                        gatsbyImage(width: 1, height: 1, layout: FULL_WIDTH)
                      }
                    }
                  }
                  ... on node__video {
                    type: node_type {
                      id: drupal_internal__target_id
                    }
                    title
                    entityUrl: path {
                      path: alias
                    }
                    fieldTargetSite: field_target_site {
                      targetId: drupal_internal__target_id
                    }
                    locale: langcode
                    fieldCustomerName: field_customer_name
                    fieldCustomerDesignation: field_customer_designation
                    fieldCustomerFeedback: field_customer_feedback
                    fieldReferenceVisibility: field_reference_visibility
                    fieldTestimonialType: field_testimonial_type
                    changed
                    status
                    fieldTeaserLogo: field_teaser_logo {
                      alt
                    }
                    fieldTeaserImage: field_teaser_image {
                      alt
                    }
                    relationships {
                      fieldHeroMedia: field_hero_media {
                        fieldImage: field_image {
                          alt
                        }
                        relationships {
                          fieldImage: field_image {
                            gatsbyImage(width: 720, placeholder:BLURRED)
                            url
                          }
                        }
                      }
                      fieldMediaImage: field_media_image {
                        fieldImage: field_image {
                          alt
                        }
                        relationships {
                          fieldImage: field_image {
                            gatsbyImage(width: 720, placeholder:BLURRED)
                          }
                        }
                      }
                      fieldTeaserImage: field_teaser_image {
                        gatsbyImage(width: 720, placeholder:BLURRED)
                      }
                      fieldTeaserLogo: field_teaser_logo {
                        gatsbyImage(width: 1, height: 1, layout: FULL_WIDTH)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
 }
`)
  const entities = data?.allNodeHomepage?.nodes?.find(x => x?.langcode === locale)
  return { accruentHomepageData: entities };
}
