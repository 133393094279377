import { graphql, useStaticQuery } from "gatsby";
export default function useSolutionData() {
    const data = useStaticQuery(graphql`
  {
    allSitePage(
      filter: {componentChunkName: {eq: "component---src-templates-solutions-template-js"}, path: {nin: "/solutions"}, context: {locale: {eq: "en"}}}
      sort: {order: ASC, fields: context___entityLabel}
      ) {
        nodes {
          context {
            key: entityId
            label:entityLabel
            relationships {
              fieldRelatedProducts {
                title
                id
                nid
                status
                uuid
                type {
                  id
                }
                body {
                  value
                }
                fieldCtaUrl {
                  url
                }
                relationships {
                  fieldRelatedItems {
                    changed
                    entityId
                    uuid
                    entityLabel
                    entityUrl {
                      path
                    }
                    fieldFilterLabel
                    fieldPageTitle
                    fieldProductDescription
                    name
                    relationships {
                      fieldProductLogo {
                        alt
                        gatsbyImage {
                          height
                          layout
                          width
                          placeholder {
                            fallback
                          }
                          images {
                            fallback {
                              sizes
                              src
                              srcSet
                            }
                            sources {
                              sizes
                              srcSet
                              type
                            }
                          }
                        }
                      }
                    }
                    fieldProductLogo {
                      alt
                    }
                  }
                  fieldProductReference {
                    status
                    relationships {
                      fieldProductReferenceItem {
                        changed
                        entityId
                        uuid
                        entityLabel
                        entityUrl {
                          path
                        }
                        fieldFilterLabel
                        fieldPageTitle
                        fieldProductDescription
                        name
                        fieldProductLogo {
                          alt
                        }
                        relationships {
                          fieldProductLogo {
                            alt
                            gatsbyImage {
                              height
                              layout
                              width
                              placeholder {
                                fallback
                              }
                              images {
                                fallback {
                                  sizes
                                  src
                                  srcSet
                                }
                                sources {
                                  sizes
                                  srcSet
                                  type
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              fieldRelatedResourceSection {
                title
                uuid
                status
                type {
                  id
                }
                nid
                fieldRelatedFormat
                fieldPreHeading
                fieldHideTitle
                fieldBody {
                  value
                }
                fieldCtaUrl {
                  title
                  url
                }
                relationships {
                  fieldRelatedItem {
                    entityId
                    changed
                    nid
                    status
                    title
                    uuid
                    locale
                    fieldResourceDescription
                    fieldReferenceVisibility
                    body {
                      value
                    }
                    entityUrl {
                      path
                    }
                    fieldTargetSite {
                      targetId
                    }
                    type {
                      id
                    }
                    relationships {
                      fieldVideoRef {
                        relationships {
                          thumbnail {
                            gatsbyImage {
                              height
                              images {
                                fallback {
                                  sizes
                                  src
                                  srcSet
                                }
                                sources {
                                  sizes
                                  srcSet
                                  type
                                }
                              }
                              layout
                              placeholder {
                                fallback
                              }
                              width
                            }
                          }
                        }
                        thumbnail {
                          alt
                        }
                      }
                      fieldMediaImage {
                        fieldImage {
                          alt
                        }
                        relationships {
                          fieldImage {
                            alt
                            gatsbyImage {
                              height
                              layout
                              width
                              placeholder {
                                fallback
                              }
                              images {
                                fallback {
                                  sizes
                                  src
                                  srcSet
                                }
                                sources {
                                  sizes
                                  srcSet
                                  type
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }

                  fieldResourceReference {
                    entityId
                    changed
                    fieldTitle
                    status
                    fieldResourceDescription
                    fieldOverrideItem
                    fieldCtaUrl {
                      title
                      url
                    }
                    fieldGraphic {
                      alt
                    }
                    relationships {
                      fieldGraphic {
                        alt
                        gatsbyImage {
                          height
                          images {
                            fallback {
                              sizes
                              src
                              srcSet
                            }
                            sources {
                              sizes
                              srcSet
                              type
                            }
                          }
                          layout
                          placeholder {
                            fallback
                          }
                          width
                        }
                        publicUrl
                        url
                      }
                      fieldResourceReferenceItem {
                        entityId
                        changed
                        body {
                          value
                        }
                        entityUrl {
                          path
                        }
                        fieldReferenceVisibility
                        fieldResourceDescription
                        fieldTargetSite {
                          targetId
                        }
                        locale
                        nid
                        status
                        title
                        type {
                          id
                        }
                        uuid
                        relationships {
                          fieldVideoRef {
                            relationships {
                              thumbnail {
                                gatsbyImage {
                                  height
                                  images {
                                    fallback {
                                      sizes
                                      src
                                      srcSet
                                    }
                                    sources {
                                      sizes
                                      srcSet
                                      type
                                    }
                                  }
                                  layout
                                  placeholder {
                                    fallback
                                  }
                                  width
                                }
                              }
                            }
                            thumbnail {
                              alt
                            }
                          }
                          fieldMediaImage {
                            fieldImage {
                              alt
                            }
                            relationships {
                              fieldImage {
                                alt
                                publicUrl
                                url
                                gatsbyImage {
                                  height
                                  images {
                                    fallback {
                                      sizes
                                      src
                                      srcSet
                                    }
                                    sources {
                                      sizes
                                      srcSet
                                      type
                                    }
                                  }
                                  layout
                                  placeholder {
                                    fallback
                                  }
                                  width
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          path
        }
      }
  }`)

    let result = data?.allSitePage?.nodes?.filter(node=>((node.path !== '/uk/solutions') || (node.path !== '/solutions')));

    return result;
}
